import React from 'react';

import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import { useSelector } from 'react-redux';
import { currentUserFirstLastNameSelector } from 'redux/selectors';
import {
  fnAddGuestToEventInCart,
  fnGetNextAvailableGuestLookupId,
} from '../redux/guestHelpers';
import { selectAllEventsInCart } from '../redux/selectors';
import useCurrentEventCartItem from '../useCurrentEventCartItem';

const AddUnnamedGuest = () => {
  const oCurrentUserName = useSelector(currentUserFirstLastNameSelector);
  const aEventsInCart = useSelector(selectAllEventsInCart);
  const oCurrentEventCartItem = useCurrentEventCartItem();

  return (
    <ButtonWithIcon
      sIcon='plus'
      fnHandleClick={() => {
        const sGuestLookupId = fnGetNextAvailableGuestLookupId(aEventsInCart);
        const sGuestName = oCurrentUserName.sPreferredName
          ? `Guest ${sGuestLookupId} of ${oCurrentUserName.sPreferredName} ${oCurrentUserName.sLastName}`
          : `Guest ${sGuestLookupId}`;
        fnAddGuestToEventInCart(
          sGuestName,
          sGuestLookupId,
          oCurrentEventCartItem.oDetails.oEvent.LOOKUPID,
          null,
          'guest'
        );
      }}
      sCypressId='add-unnamed-guest-button'
    >
      Add Unnamed Guest
    </ButtonWithIcon>
  );
};

export default AddUnnamedGuest;
