import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import store, { persistor } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { useLocation, useNavigate } from 'react-router-dom';

import UserAuthManager from 'components/UserAuthManager';
import oWnApi from 'utils/WnApi';
import { fnIsDateTimeWithinRange } from 'utils/common';
import { fnSignOut } from 'components/NavBar/helpers';
import UpcomingDowntimeBanner from 'components/Downtime/UpcomingDowntimeBanner';
import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import PreviousLocationContext from 'utils/PreviousLocationContext';
import PublicNavBar from 'components/NavBar/PublicNavBar';
import metaActions from 'redux/meta/actions';
import AppRoutes from './utils/AppRoutes';
import NavBar from './components/NavBar';
import Feedback from './components/Feedback';
import Footer from './components/Footer';

import './styles/styles.scss';

const App = () => {
  const fnNavigate = useNavigate();
  const oLocation = useLocation();

  const oCurrentLocation = useRef(null);
  const [oPreviousLocation, fnSetPreviousLocation] = useState(null);

  const [aMaintenanceAlerts, fnSetMaintenanceAlerts] = useState([]);
  const [bIsInMaintenanceWindow, fnSetIsInMaintenanceWindow] = useState(false);

  // Redirect to the downtime page if we're in a maintenance window
  useEffect(() => {
    oWnApi.get('status', false).then((oStatus) => {
      const aAlerts = oStatus.data.Item.alerts.L;
      fnSetMaintenanceAlerts(aAlerts);
      const oActiveMaintenanceAlert = aAlerts.find((oAlert) => {
        const sStart = oAlert.M.start.S;
        const sEnd = oAlert.M.end.S;
        return fnIsDateTimeWithinRange(Date.now(), sStart, sEnd);
      });
      if (oActiveMaintenanceAlert) {
        fnSetIsInMaintenanceWindow(true);
        fnSignOut();
        fnNavigate('/downtime', {
          state: { sMessage: oActiveMaintenanceAlert.M.message.S },
        });
      } else if (oLocation.pathname.includes('downtime')) {
        // Redirect users to sign in once the downtime is over
        fnNavigate('/sign-in');
      }
    });
  }, [fnNavigate, oLocation.pathname]);

  // Track the previous page's location
  useEffect(() => {
    fnSetPreviousLocation(oCurrentLocation.current);
    oCurrentLocation.current = oLocation;
  }, [oLocation]);

  const oMemoizedPreviousLocation = useMemo(
    () => ({ oPreviousLocation }),
    [oPreviousLocation]
  );

  // Fetch app feature flags when the location changes
  useEffect(() => {
    store.dispatch(metaActions.getFeatureFlags());
  }, [oLocation]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <UserAuthManager>
          {({ oUser }) => (
            <>
              <a href='#main-content' className='app__skipToContentLink'>
                Skip to main content
              </a>
              <PreviousLocationContext.Provider
                value={oMemoizedPreviousLocation}
              >
                <div id='app' className='app'>
                  {oUser ? (
                    <NavBar />
                  ) : (
                    <PublicNavBar
                      bIsInMaintenanceWindow={bIsInMaintenanceWindow}
                    />
                  )}
                  {oUser && aMaintenanceAlerts.length > 0 && (
                    <UpcomingDowntimeBanner
                      aMaintenanceAlerts={aMaintenanceAlerts}
                    />
                  )}
                  <main className='app__main' id='main-content'>
                    <PageErrorBoundary fnNavigate={fnNavigate}>
                      <AppRoutes
                        appProps={{
                          user: oUser,
                          aMaintenanceAlerts,
                        }}
                      />
                    </PageErrorBoundary>
                  </main>
                  {oUser && <Feedback />}
                  {oLocation.pathname !== '/sign-in' && (
                    <Footer
                      bIsInMaintenanceWindow={bIsInMaintenanceWindow}
                      oUser={oUser}
                    />
                  )}
                </div>
              </PreviousLocationContext.Provider>
            </>
          )}
        </UserAuthManager>
      </PersistGate>
    </Provider>
  );
};

export default App;
