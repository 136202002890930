import * as R from 'ramda';

import * as CheckoutActions from 'components/Checkout/redux/actions';
import store from 'redux/store';
import { fnBuildRegisteredGuest } from './guestHelpers';

/**
 * Removes superfluous data from the event object
 * to reduce how much data goes into the Redux store's cart.
 *
 * @param {object} oEvent - Event details data from DAD.
 * @returns {object}
 */
const fnPrepEventForCart = (oEvent) =>
  R.omit(['ATTENDEES', 'MY_PARTY'], oEvent);

/**
 * Convert the MY_PARTY object to an array of guests.
 * @param {object} oEvent - Event details data from DAD.
 * @returns {array}
 */
export const fnConvertMyPartyToGuestsArray = (oEvent) => {
  const { HOST, GUESTS = [] } = oEvent?.MY_PARTY || {};
  let aMyPartyGuests = [];
  if (GUESTS.length > 0) {
    aMyPartyGuests = GUESTS.map(
      ({ NAME, LOOKUPID, REG_OPTION, ADDITIONAL_INFO }) =>
        fnBuildRegisteredGuest(
          NAME,
          'guest',
          LOOKUPID,
          REG_OPTION,
          ADDITIONAL_INFO
        )
    );
  }
  const oHost = fnBuildRegisteredGuest(
    HOST.NAME,
    'currentHost',
    HOST.LOOKUPID,
    HOST.REG_OPTION,
    HOST.ADDITIONAL_INFO
  );

  const aGuests = [oHost, ...aMyPartyGuests];
  return aGuests;
};

const fnGetEventCartItem = (sEventLookupId) =>
  store.getState().Checkout.aCart.find((oItem) => oItem.sId === sEventLookupId);

export const fnAddEventToCart = (oEvent) => {
  const oCartItem = {
    sType: 'event',
    sId: oEvent.LOOKUPID,
    sDisplayName: oEvent.TITLE,
    nPrice: 0,
    oDetails: {
      oEvent: fnPrepEventForCart(oEvent),
      aGuests: [],
    },
  };
  store.dispatch(CheckoutActions.cart.addItem(oCartItem));
};

export const fnAddEventRegistrationCancellationToCart = (oEvent) => {
  const oCartItem = {
    sType: 'event-cancellation',
    sId: oEvent.LOOKUPID,
    sDisplayName: oEvent.TITLE,
    nPrice: 0,
    oDetails: {
      oEvent: fnPrepEventForCart(oEvent),
      aGuests: fnConvertMyPartyToGuestsArray(oEvent),
    },
  };
  store.dispatch(CheckoutActions.cart.addItem(oCartItem));
};

export const fnAddEventRegistrationEditToCart = (oEvent) => {
  const oCartItem = {
    sType: 'event-edit',
    sId: oEvent.LOOKUPID,
    sDisplayName: oEvent.TITLE,
    nPrice: 0,
    oDetails: {
      oEvent: fnPrepEventForCart(oEvent),
      aGuests: fnConvertMyPartyToGuestsArray(oEvent),
    },
  };
  store.dispatch(CheckoutActions.cart.addItem(oCartItem));
};

export const fnAddWaitlistEventToCart = (oEvent) => {
  const oCartItem = {
    sType: 'event-waitlist',
    sId: oEvent.LOOKUPID,
    sDisplayName: oEvent.TITLE,
    nPrice: 0,
    oDetails: {
      oEvent: fnPrepEventForCart(oEvent),
      aGuests: [],
    },
  };
  store.dispatch(CheckoutActions.cart.addItem(oCartItem));
};

export const fnRefreshEventDataInCart = (oEvent) => {
  const oEventCartItem = fnGetEventCartItem(oEvent.LOOKUPID);
  const oEventCartItemCopy = R.clone(oEventCartItem);

  const oRefreshedEventCartItem = {
    ...oEventCartItemCopy,
    sDisplayName: oEvent.TITLE,
    oDetails: {
      ...oEventCartItemCopy.oDetails,
      oEvent: fnPrepEventForCart(oEvent),
    },
  };

  store.dispatch(CheckoutActions.cart.updateItem(oRefreshedEventCartItem));
};

export const fnUpdateEventCapacityInCart = (
  sEventLookupId,
  iNewCapacityRemaining
) => {
  const oEventCartItem = fnGetEventCartItem(sEventLookupId);
  const { TOTAL_REGISTRATIONS, TOTAL_CAPACITY } =
    oEventCartItem.oDetails.oEvent.REGISTRATION;

  const iOldCapacityRemaining = TOTAL_CAPACITY - TOTAL_REGISTRATIONS;
  if (TOTAL_CAPACITY > 0 && iOldCapacityRemaining !== iNewCapacityRemaining) {
    const oUpdatedEventCartItem = R.clone(oEventCartItem);
    oUpdatedEventCartItem.oDetails.oEvent.REGISTRATION.TOTAL_REGISTRATIONS =
      TOTAL_CAPACITY - iNewCapacityRemaining;
    store.dispatch(CheckoutActions.cart.updateItem(oUpdatedEventCartItem));
  }
};

/**
 * Changes the event item in the cart's registration type(sType) to 'event-waitlist'
 */
export const fnUpdateEventRegistrationTypeToWaitlist = (sEventLookupId) => {
  const oEventCartItem = fnGetEventCartItem(sEventLookupId);
  const oUpdatedEventCartItem = R.clone(oEventCartItem);
  oUpdatedEventCartItem.sType = 'event-waitlist';
  store.dispatch(CheckoutActions.cart.updateItem(oUpdatedEventCartItem));
};

export const fnRemoveEventFromCart = (sId) => {
  store.dispatch(CheckoutActions.cart.removeItem(sId));
};
