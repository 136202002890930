import React from 'react';
import { Link } from 'react-router-dom';

const ConditionalContactUsLink = ({ bIsInMaintenanceWindow = false }) =>
  bIsInMaintenanceWindow ? (
    <a
      href='mailto:wakenetwork.help@wfu.edu'
      className='landingPage__contactLink'
    >
      Contact Us
    </a>
  ) : (
    <Link to='/sign-up/help/noverify' className='landingPage__contactLink'>
      Contact Us
    </Link>
  );

export default ConditionalContactUsLink;
