import React from 'react';
import { useSelector } from 'react-redux';

import EventRegistrationDetailsCard from '../EventRegistrationDetailsCard';
import { selectAllNamedEventGuests } from '../AdditionalInfo/selectors';
import EventRegistrantName from '../EventRegistrantName';
import { fnCombineGuestSpecialNeeds } from './helpers';

const AdditionalInfoConfirmation = () => {
  const aNamedGuests = useSelector(selectAllNamedEventGuests);

  return (
    <EventRegistrationDetailsCard
      sTitle='Additional Guest Information'
      sCypressId='confirmation-additional-info'
    >
      <table className='eventConfirmation__additionalInfoTable'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Dietary Restrictions and Additional Information</th>
          </tr>
        </thead>
        <tbody>
          {aNamedGuests.map((oNamedGuest) => (
            <tr
              key={oNamedGuest.LOOKUPID}
              data-cy={`registrant-${oNamedGuest.LOOKUPID}-row`}
            >
              <td>
                <EventRegistrantName
                  sName={oNamedGuest.NAME}
                  sStatus={oNamedGuest.ROLE}
                  bShowRoleLabel={false}
                />
              </td>
              <td data-cy='registrant-combined-special-needs'>
                {fnCombineGuestSpecialNeeds(oNamedGuest.ADDITIONAL_INFO)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </EventRegistrationDetailsCard>
  );
};

export default AdditionalInfoConfirmation;
