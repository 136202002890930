import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import ButtonWithPopupMenu from 'components/MyProfile/elements/layout/ButtonWithPopupMenu';
import { Radio } from 'components/Form/Fields';
import Button from 'components/Buttons/Button';
import { useSelector } from 'react-redux';
import { currentUserLookupIdSelector } from 'redux/selectors';
import {
  fnRemoveGuestFromEventInCart,
  fnUpdateGuestRole,
} from '../redux/guestHelpers';

interface PartyMemberSettingsProps {
  sGuestLookupId: string;
  iEventFieldIndex: number;
  iGuestFieldIndex: number;
}

const PartyMemberSettings: React.FC<PartyMemberSettingsProps> = ({
  sGuestLookupId,
  iEventFieldIndex,
  iGuestFieldIndex,
}) => {
  const currentUserLookupId = useSelector(currentUserLookupIdSelector);
  const oFormikContext: any = useFormikContext();
  const eventLookupId: string =
    oFormikContext.values.events[iEventFieldIndex].sEventLookupId;
  const guestLookupId: string =
    oFormikContext.values.events[iEventFieldIndex].guests[iGuestFieldIndex]
      .sGuestLookupId;
  const currentRole =
    oFormikContext.values.events[iEventFieldIndex].guests[iGuestFieldIndex]
      .sRole;

  // Sync the role with the Redux store
  useEffect(() => {
    fnUpdateGuestRole(guestLookupId, eventLookupId, currentRole);
  }, [
    oFormikContext.values.events[iEventFieldIndex].guests[iGuestFieldIndex]
      .sRole,
    iEventFieldIndex,
    iGuestFieldIndex,
  ]);

  return (
    <ButtonWithPopupMenu>
      <div className='optionsModal__section partyMemberSettings__popupTitle'>
        Settings
      </div>
      <fieldset className='optionsModal__section'>
        <legend className='optionsModal__sectionTitle'>Select Role</legend>
        <Radio
          sId={`selectRoleHost-${sGuestLookupId}`}
          sName={`events.${iEventFieldIndex}.guests.${iGuestFieldIndex}.sRole`}
          sLabel='Primary Contact'
          mValue='currentHost'
          sUi='buttons'
        />
        <Radio
          sId={`selectRoleGuest-${sGuestLookupId}`}
          sName={`events.${iEventFieldIndex}.guests.${iGuestFieldIndex}.sRole`}
          sLabel='Guest'
          mValue='guest'
          sUi='buttons'
        />
        {guestLookupId === currentUserLookupId && (
          <Radio
            sId={`selectRoleFormerHost-${sGuestLookupId}`}
            sName={`events.${iEventFieldIndex}.guests.${iGuestFieldIndex}.sRole`}
            sLabel='I am not attending'
            mValue='formerHost'
            sUi='buttons'
          />
        )}
      </fieldset>
      {guestLookupId !== currentUserLookupId && (
        <div className='optionsModal__section'>
          <Button
            sFlavor='secondary'
            fnHandleClick={() => {
              fnRemoveGuestFromEventInCart(guestLookupId, eventLookupId);
            }}
          >
            Remove Guest
          </Button>
        </div>
      )}
    </ButtonWithPopupMenu>
  );
};

export default PartyMemberSettings;
