import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import oIconLibrary from 'utils/iconLibrary';
import EventRegistrantName from 'components/EventRegistration/EventRegistrantName';

/**
 * Displays the host and members of the user's party who are attending an event.
 */
const MyParty = ({ oParty, bIsWaitlisted }) => {
  const { HOST, GUESTS = [] } = oParty;
  const sTitle = bIsWaitlisted
    ? 'On the Waitlist'
    : 'Your Registration Information';

  return (
    <div className='eventDetails__sectionWithLine'>
      <p className='t-paragraph--bold'>
        <FontAwesomeIcon icon={oIconLibrary.fontAwesome.users} /> {sTitle}
      </p>
      <ul className='myParty__list'>
        <li key={HOST.LOOKUPID}>
          <EventRegistrantName
            sName={HOST.NAME}
            sStatus={bIsWaitlisted ? 'waitlist' : 'currentHost'}
          />
        </li>
        {GUESTS.map((oGuest) => (
          <li key={oGuest.LOOKUPID}>
            {!oGuest.LOOKUPID ? (
              oGuest.NAME
            ) : (
              <EventRegistrantName
                sName={oGuest.NAME}
                sStatus={bIsWaitlisted ? 'waitlist' : 'guest'}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

MyParty.propTypes = {
  oParty: PropTypes.shape({
    HOST: PropTypes.shape({
      LOOKUPID: PropTypes.string,
      NAME: PropTypes.string,
    }),
    GUESTS: PropTypes.arrayOf(
      PropTypes.shape({
        LOOKUPID: PropTypes.string,
        NAME: PropTypes.string,
      })
    ),
  }).isRequired,
  bIsWaitlisted: PropTypes.bool.isRequired,
};

export default MyParty;
