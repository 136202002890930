import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import oIconLibrary from 'utils/iconLibrary';

const statusMap = {
  currentHost: 'Primary Contact',
  formerHost: 'Self, Not Attending',
  guest: 'Guest',
  waitlist: '',
};

const EventRegistrantName = ({ sName, sStatus, bShowRoleLabel = true }) => (
  <div className='eventRegistrantName__wrapper' data-cy='event-registrant-name'>
    <p className='eventRegistrantName'>
      <FontAwesomeIcon
        icon={oIconLibrary.fontAwesome.user}
        className={`eventRegistrantName__icon--${sStatus}`}
      />
      <span className='t-paragraph'>{sName}</span>
    </p>
    {bShowRoleLabel && (
      <p className='eventRegistrantName__roleLabel t-paragraph--small'>
        &#40;{statusMap[sStatus]}&#41;
      </p>
    )}
  </div>
);

EventRegistrantName.propTypes = {
  sName: PropTypes.string.isRequired,
  sStatus: PropTypes.oneOf(['currentHost', 'formerHost', 'guest', 'waitlist'])
    .isRequired,
  bShowRoleLabel: PropTypes.bool,
};

EventRegistrantName.defaultProps = {
  bShowRoleLabel: true,
};

export default EventRegistrantName;
