import { createSelector } from 'reselect';

/**
 * Selects all items in the cart.
 *
 * @param {object} state - Redux store state
 * @returns {array} - List of cart items
 */
export const selectCart = (state) => state.Checkout.aCart;

/**
 * Selects all events and waitlisted events in the cart.
 */
export const selectAllEventsInCart = createSelector([selectCart], (aCart) =>
  aCart.filter((oCartItem) =>
    ['event', 'event-waitlist', 'event-edit'].includes(oCartItem.sType)
  )
);

/**
 * Returns an array of all the events in the cart
 */
export const selectEventsInCart = createSelector([selectCart], (aCart) =>
  aCart.filter((oCartItem) => oCartItem.sType === 'event')
);

/**
 * Selects all waitlisted events in the cart.
 */
export const selectWaitlistEventsInCart = createSelector(
  [selectCart],
  (aCart) => aCart.filter((oCartItem) => oCartItem.sType === 'event-waitlist')
);

/**
 * Selects all event registrations in the cart to be cancelled.
 */
export const selectEventsToCancel = createSelector([selectCart], (aCart) =>
  aCart.filter((oCartItem) => oCartItem.sType === 'event-cancellation')
);

/**
 * Selects all event registrations in the cart to be edited.
 */
export const selectEventsToEdit = createSelector([selectCart], (aCart) =>
  aCart.filter((oCartItem) => oCartItem.sType === 'event-edit')
);

/**
 * Determines if the user is editing an event registration.
 */
export const selectIsEditingEventRegistration = createSelector(
  [selectEventsToEdit],
  (aEventsToEdit) => aEventsToEdit.length > 0
);

/**
 * Selects an event to cancel by lookup id.
 */
export const selectEventToCancelBySlug = (sEventSlug) =>
  createSelector([selectEventsToCancel], (aEventsToCancel) =>
    aEventsToCancel.find((oEvent) => oEvent.oDetails.oEvent.SLUG === sEventSlug)
  );

export const selectEventInCartByLookupId = (sEventLookupId) =>
  createSelector([selectAllEventsInCart], (aEventsInCart) =>
    aEventsInCart.find((oEvent) => oEvent.sId === sEventLookupId)
  );

export const selectEventInCartBySlug = (sEventSlug) =>
  createSelector([selectAllEventsInCart], (aEventsInCart) =>
    aEventsInCart.find((oEvent) => oEvent.oDetails.oEvent.SLUG === sEventSlug)
  );
