import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useWindowSize from 'utils/hooks/useWindowSize';
import { selectIsEditingEventRegistration } from 'components/EventRegistration/redux/selectors';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import ConditionalNavLink from './ConditionalNavLink';
import { selectEventRegNavSteps } from './redux/selectors';

const ReviewRegistrationSubNav = ({ iMaxStepVisitedIndex }) => {
  const { sScreenSize } = useWindowSize();
  const bIsEditingRegistration = useSelector(selectIsEditingEventRegistration);
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const aAllEventRegistrationSteps = useSelector((state) =>
    selectEventRegNavSteps(state, bIsUserAuthenticated)
  );

  // Only show the submenu on medium or larger screens
  return sScreenSize !== 'small' ? (
    <ol className='eventRegistrationNav__secondaryList'>
      <li>
        <ConditionalNavLink
          sTo='/events/register/additional-info'
          bCondition={
            iMaxStepVisitedIndex >=
            aAllEventRegistrationSteps.indexOf(
              '/events/register/additional-info'
            )
          }
          className='eventRegistrationNav__secondaryLink t-paragraph--small'
        >
          Additional Information
        </ConditionalNavLink>
      </li>
      {bIsUserAuthenticated && (
        <li>
          <ConditionalNavLink
            sTo='/events/register/contact'
            bCondition={
              iMaxStepVisitedIndex >=
              aAllEventRegistrationSteps.indexOf('/events/register/contact')
            }
            className='eventRegistrationNav__secondaryLink t-paragraph--small'
          >
            Contact
          </ConditionalNavLink>
        </li>
      )}
      <li>
        <ConditionalNavLink
          sTo='/events/register/review'
          bCondition={
            iMaxStepVisitedIndex >=
            aAllEventRegistrationSteps.indexOf('/events/register/review')
          }
          className='eventRegistrationNav__secondaryLink t-paragraph--small'
        >
          {bIsEditingRegistration
            ? 'Update Current Registration'
            : 'Review Pending Registration'}
        </ConditionalNavLink>
      </li>
    </ol>
  ) : null;
};

ReviewRegistrationSubNav.propTypes = {
  iMaxStepVisitedIndex: PropTypes.number.isRequired,
};

export default ReviewRegistrationSubNav;
