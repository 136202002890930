import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
  selectCurrentUserEmail,
} from 'redux/selectors';
import store from 'redux/store';
import * as CheckoutActions from 'components/Checkout/redux/actions';

export const fnCalculateTotalCartCost = (aCartItems) => {
  const nTotalCost = aCartItems.reduce(
    (nTotal, oCartItem) => nTotal + oCartItem.nPrice,
    0
  );
  return nTotalCost;
};

/**
 * Sets the user's information in the Checkout section of the Redux store.
 */
export const fnSetCheckoutUserInfo = () => {
  const oCurrentState = store.getState();
  const oCurrentUserName = currentUserFirstLastNameSelector(oCurrentState);
  const sCurrentUserEmail = selectCurrentUserEmail(oCurrentState);
  const sCurrentUserLookupId = currentUserLookupIdSelector(oCurrentState);

  if (!oCurrentUserName || !sCurrentUserEmail || !sCurrentUserLookupId) {
    return;
  }

  store.dispatch(
    CheckoutActions.update({
      fullName: oCurrentUserName.sName,
      firstName: oCurrentUserName.sPreferredName,
      lastName: oCurrentUserName.sLastName,
      email: sCurrentUserEmail,
      userLookupId: sCurrentUserLookupId,
    })
  );
};

export default {};
